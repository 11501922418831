import React from "react"
import { Link, graphql } from "gatsby"
import SEO from "../../components/seo-rando"
import Layout from "../../components/layout"
import "../../styles/main.scss"
import "../../styles/rando.scss"
import WalkImg from "../../components/itemimg"

const RandoPage = ({ data }) => {
  return (
    <Layout>
      <SEO
        title="Trekathlon"
        descriptiom="Descriptions de randonnées et balades"
        keywords={[
          `Trekking`,
          `Travel`,
          `Tips`,
          `Budget`,
          `Photography`,
          `Travel`,
        ]}
        meta={[{ name: "robots", content: "noindex" }]}
      />
      <section class="selectpage">
        <div class="counter">
          {data.allMarkdownRemark.totalCount} balades r&eacute;pertori&eacute;es
        </div>
        <div class="group">
          {data.allMarkdownRemark.edges.map(({ node }) => (
            <Link to={node.fields.slug}>
              <div class="box" key={node.id}>
                <div class="img">
                  <WalkImg
                    filename={node.frontmatter.picture}
                    alt={node.frontmatter.name}
                  />
                </div>
                <div class="detail">
                  <div class="head">
                    <div class="name">{node.frontmatter.name}</div>
                    <div class="tags">
                      <i class={node.frontmatter.tag1}></i>{" "}
                      <i class={node.frontmatter.tag2}></i>{" "}
                      <i class={node.frontmatter.tag3}></i>
                    </div>
                  </div>
                  <div class="desc">{node.frontmatter.shortdesc}</div>
                  <div class="numbers">
                    <div>
                      <i class={node.frontmatter.country}></i>{" "}
                    </div>
                    <div>
                      <i class="em em-world_map"></i> :{" "}
                      {node.frontmatter.distance}{" "}
                    </div>
                    <div>
                      <i class="em em-clock230"></i> : {node.frontmatter.time}{" "}
                    </div>
                    <div>
                      <i class="em em-snow_capped_mountain"></i> :{" "}
                      {node.frontmatter.cumulativeup}{" "}
                    </div>
                  </div>
                </div>
              </div>
            </Link>
          ))}
        </div>
      </section>
    </Layout>
  )
}

export default RandoPage

export const query = graphql`
  query {
    allMarkdownRemark(
      filter: { frontmatter: { section: { eq: "rando" } } }
      sort: { fields: [frontmatter___category, frontmatter___name] }
    ) {
      totalCount
      edges {
        node {
          id
          frontmatter {
            section
            category
            name
            country
            time
            distance
            cumulativeup
            tag1
            tag2
            tag3
            shortdesc
            picture
            published(formatString: "YYYY/MM/DD")
          }
          fields {
            slug
          }
        }
      }
    }
  }
`
